<template>
  <div data-saved-diagnosis-list style="position: relative">
    <div class="flex" style="position: absolute; top: -64px; right: 0">
      <router-link
        class="flex items-center color-secondary"
        :to="{ name: 'DiagnosisGroupCreate' }"
        ><font-awesome-icon icon="plus-square" class="fs-36" />
        <span data-create-diagnosis-group-btn class="rfs-m-2-l">Create New Diagnosis Group</span>
      </router-link>
    </div>
    <portal to="before-content" v-if="!breakpoints.greaterThanMd">
      <div class="filter-bar rfs-p-8-l">
        <font-awesome-icon icon="filter" />
        <button
          type="button"
          class="btn btn-text"
          :class="[{ active: smScreenControlsShow }]"
          @click="toggleControls()"
        >
          Filter Diagnosis Groups <font-awesome-icon icon="angle-down" />
        </button>
      </div>
    </portal>
    <!-- Result data -->
    <transition-group name="fade">
      <div key="1" v-if="diagnosisGroups !== undefined && diagnosisGroups.length === 0">
        <p class="card bg-warning-contrast color-white fw-600" >
          <font-awesome-icon class="" icon="exclamation-circle" />
          There are no Diagnosis Groups.
        </p>
      </div>
      <div class="grid gap-4 xs:grid-cols-1 sm:grid-cols-2" key="2" v-if="diagnosisGroups !== undefined && diagnosisGroups.length > 0">
        <template v-for="(digGrp, i) in getDiagnosisGroups">
          <div class="card" :key="'digGrp_'+i">
            <div class="card-meta-grid">
              <div class="card-meta-label">
                <span class="fs-20 break-word card-title">{{ digGrp.diagnosisGroupName }}</span>
                <br/>
                <span class="fs-11 break-word">{{ digGrp.diagnosisList.length }} Total Diagnosis codes</span>
              </div>
              <div class="card-top-right-corner">
                <router-link
                  :to="{
                    name: 'DiagnosisGroupEdit',
                    params: { groupId: digGrp.id }
                  }"
                  manage-diagnosis-edit-btn
                >
                  <font-awesome-icon style="cursor: pointer" icon="edit" />
                </router-link>
              </div>
            </div>
            <div class="card-subtitle pl-5">Diagnoses</div>
            <div class="card-body mt-2">
              <div class="card-meta-grid">
                <div class="grid gap-4 grid-cols-2 xl:grid-cols-2">
                  <div class="pl-15">
                    <div class="fs-12">
                      <template v-for="(dig, j) in digGrp.diagnosisList.slice(0, 5)">
                        <span class="text-overflow-dot" :key="'dig1_'+i+j"> {{ dig }} </span>
                        <br v-if="j < 4" :key="'dig1Br_'+i+j"/>
                      </template>
                    </div>
                  </div>
                  <div class="" v-if="digGrp.diagnosisList.length >= 6">
                    <div class="fs-12">
                      <template v-for="(dig, j) in digGrp.diagnosisList.slice(5, 9)">
                        <span class="text-overflow-dot" :key="'dig2_'+i+j"> {{ dig }} </span>
                        <br v-if="j < 3" :key="'dig2Br_'+i+j"/>
                      </template>
                      <template v-if="digGrp.diagnosisList.length === 10">
                        <br>
                        <span class="text-overflow-dot"> {{ digGrp.diagnosisList[9] }} </span>
                      </template>
                      <template v-if="digGrp.diagnosisList.length > 10">
                        <br>
                        <div class="grid gap-2 grid-cols-2">
                          <div class="text-overflow-dot"> {{ digGrp.diagnosisList[9] }} </div>
                          <div> +{{digGrp.diagnosisList.length - 10}} more </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </transition-group>
    <!-- Load More -->
    <transition-group name="fade">
      <div v-if="diagnosisGroups !== undefined && diagnosisGroups.length > 0 && loadMoreVisible"
        key="3" class="text-center rfs-m-8">
        <button
          data-load-more
          class="btn btn-sm btn-primary rfs-m-4-l"
          @click="loadMore">
          <span>Load More</span>
        </button>
      </div>
    </transition-group>
    <!-- Loading window -->
    <transition-group name="fade">
      <div v-if="loadingData" key="2" class="text-center rfs-m-8">
        <loader />
      </div>
    </transition-group>
    <portal to="after-content">
      <div
        class="right-side-bar"
        :class="{
          'sm-screen-contols-show':
            smScreenControlsShow && !breakpoints.greaterThanMd,
          'sm-screen-contols': !breakpoints.greaterThanMd
        }"
      >
        <div
          class="rsb-content auto-scroll"
          :class="{ 'has-footer': !breakpoints.greaterThanMd }"
        >
          <form @submit.prevent="">
            <h3 class="rfs-18 fw-sb rfs-m-4-b">Diagnosis Group Matching Search Term</h3>
            <div
              class="single-input-search"
            >
              <text-input
                :value="searchTerm"
                fieldLabel="Search"
                type="search"
                class="rfs-m-t-b rfs-m-4-b"
                fieldNote="Search by Group Name, Diagnosis Description &amp; Diagnosis Codes(ICD10/CCS)"
                @input="onFilterChange"
              />
            </div>
          </form>
        </div>
      </div>
    </portal>
  </div>
</template>

<script>
  import TextInput from '@/components/base/forms/text_input.vue';
  import card from '@/components/base/card';
  import CodeLookup from '../../../components/filters/CodeLookup';

  export default {
    name: 'DiagnosisGroupManagement',
    components: {
      TextInput,
      CodeLookup,
      card
    },
    data() {
      return {
        modalScroll: true,
        smScreenControlsShow: false,
        offset: 0,
        pageSize: 10,
        loadingData: true,
        diagnosisGroups: undefined,
        diagnosisGroupList: undefined,
        loadMoreVisible: true,
        searchTerm: ''
      };
    },
    computed: {
      getDiagnosisGroups() {
        return this.diagnosisGroups;
      }
    },
    methods: {
      toggleControls() {
        this.smScreenControlsShow = !this.smScreenControlsShow;
      },
      onFilterChange(v) {
        this.searchTerm = v;
        if ((v !== undefined && v.length >= 2)
            || v === undefined || v.length === 0) {
          this.offset = 0;
          this.getDiagnosisGroupList();
        }
      },
      getDiagnosisGroupList() {
        const payload = {'searchString': this.searchTerm, 'offset': this.offset, 'pageSize': this.pageSize};
        this.$store.dispatch('diagnosisGroupsManagement/getDiagnosisGroupsList', payload).then(() => {
          let d = this.$store.getters['diagnosisGroupsManagement/getDiagnosisList'];
          this.loadMoreVisible = (d.length === 0 || d.length < this.pageSize)
            ? false : ((d.length === 10) ? true : this.loadMoreVisible);
          this.updateDiagnosisGroups(d);
        });
      },
      loadMore() {
        this.offset += 10;
        const payload = {'searchString': this.searchTerm, 'offset': this.offset, 'pageSize': this.pageSize};
        this.$store.dispatch('diagnosisGroupsManagement/getDiagnosisGroupsList', payload).then(() => {
          let d = this.$store.getters['diagnosisGroupsManagement/getDiagnosisList'];
          if(d.length > 0) {
            this.loadMoreVisible = (d.length < this.pageSize) ? false : this.loadMoreVisible;
            this.updateDiagnosisGroups(d, true);
          } else {
            this.loadMoreVisible = false;
          }
        });
      },
      updateDiagnosisGroups(d, isLoadMore = false) {
        this.diagnosisGroupList = d;

        const dMapped = [];
        for(let dignsisGrp of d) {
          if (dignsisGrp.valueCodes !== undefined && dignsisGrp.valueCodes.length > 0) {
            let ret = {};
            ret.id = dignsisGrp.id;
            ret.codeType = dignsisGrp.codeType;
            ret.diagnosisGroupName = dignsisGrp.name;
            ret.diagnosisList = dignsisGrp.valueCodes.map(dignsis => dignsis.description);
            dMapped.push(ret);
          }
        }
        if(isLoadMore) {
          const newdiagnosisGroups = (dMapped !== undefined && dMapped.length > 0) ? dMapped : [];
          this.diagnosisGroups = this.diagnosisGroups.concat(newdiagnosisGroups);
        } else {
          this.diagnosisGroups = (dMapped !== undefined && dMapped.length > 0) ? dMapped : [];
        }
        this.loadingData = false;
      }
    },
    mounted() {
      this.getDiagnosisGroupList();
    }
  };
</script>

<style>
  .break-word {
    word-break: break-word;
  }
  .fs-20 {
    font-size: 20px;
  }
  .fs-12 {
    font-size: 12px;
  }
  .fs-11 {
    font-size: 11px;
  }
  .pl-5 {
    padding-left: 5px;
  }
  .pl-15 {
    padding-left: 15px;
  }
  .mt-2 {
    margin-top: 2px;
  }
  .text-overflow-dot {
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .card-subtitle {
    margin-bottom: 0 !important;
  }

  @media (max-width: 1024px) {
    #appContent {
      min-height: calc(100vh - 220px);
      margin-top: 114px;
    }
  }
</style>
