<template>
  <card
    :metaLabel="metaLabel"
    :title="drugGroupDesc"
    titleTag="h4"
    v-responsive="{ small: (el) => el.width <= 425 }"
  >
    <template slot="subtitle">
      <div v-if="ndcsPurchased">
        {{
          ndcsPurchased | formatNumber({ format: 'largeNumberAbbr', places: 0 })
        }}
        NDC<span v-if="ndcsPurchased != 1">s</span> Purchased
      </div>
      <div v-else>0 NDCs Purchased</div>
    </template>

    <template slot="top-right-corner">
      <router-link
        :to="{
          name: 'MedicationGroupEdit',
          params: { groupId: this.drugGroup.description.id }
        }"
        data-drug-group-edit-btn
      >
        <font-awesome-icon
          @click="$refs.about.showAboutMessage()"
          style="cursor: pointer"
          icon="edit"
        />
      </router-link>
    </template>

    <div class="card-percent-bar" v-if="drugGroupHasPurchases">
      <h5>% of Total Purchased by Account Type</h5>
      <horizontal-bar
        class="percent-by-account-type-chart"
        :chartData="percentPurchasesByAcountTypeData"
        :chartOptions="percentPurchasesByAcountTypeOptions"
        :addDataLablesPlugin="true"
      />
    </div>
    <div v-else>No purchase data found.</div>
  </card>
</template>

<script>
  import card from '@/components/base/card';
  import HorizontalBar from '@/components/charts/HorizontalBar.vue';
  export default {
    name: 'drugGroupCard',
    components: {
      card,
      HorizontalBar
    },
    props: [
      'drugGroup',
      'drugGroupDesc',
      'drugGroupHasPurchases',
      'ndcsPurchased',
      'ndcTotalSpend',
      'percentPurchasesByAcountTypeData'
    ],
    data() {
      return {
        ndcsPurchased2: 100000000,
        percentPurchasesByAcountTypeOptions: {
          plugins: {
            datalabels: {
              align: 'middle',
              textAlign: 'center',
              color: '#fff',
              display: function (context) {
                return context.dataset.data[context.dataIndex] > 5;
              },
              font: {
                weight: 'bold'
              },
              formatter: function (value, context) {
                return context.dataset.label + '\n' + value + '%';
              },
              padding: 0,
              offset: -1
            }
          },
          legend: {
            display: false
          },
          scales: {
            xAxes: [
              {
                display: false,
                stacked: true,
                ticks: {
                  fontColor: 'white'
                },
                gridLines: {
                  display: false
                }
              }
            ],
            yAxes: [
              {
                stacked: true,
                ticks: {
                  fontColor: 'white'
                },
                gridLines: {
                  display: false
                }
              }
            ]
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                var label = data.datasets[tooltipItem.datasetIndex].label;
                var val =
                  data.datasets[tooltipItem.datasetIndex].data[
                    tooltipItem.index
                  ];
                return label + ': ' + val + '%';
              }
            }
          }
        }
      };
    },
    computed: {
      metaLabel() {
        return (
          this.drugGroup.description.ndcCount +
          ' Total NDC' +
          (this.drugGroup.description.ndcCount == 1 ? '' : 's') 
        );
      }
    }
  };
</script>
