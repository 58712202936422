<template>
  <div style="position: relative">
    <div class="flex" style="position: absolute; top: -64px; right: 0">
      <router-link
        class="flex items-center color-secondary"
        :to="{ name: 'MedicationGroupCreate' }"
        ><font-awesome-icon icon="plus-square" class="fs-36" />
        <span data-create-drug-group-btn class="rfs-m-2-l"
          >Create New Medication Group</span
        ></router-link
      >
    </div>

    <portal to="before-content" v-if="!breakpoints.greaterThanMd">
      <div class="filter-bar rfs-p-8-l">
        <font-awesome-icon icon="filter" />
        <button
          type="button"
          class="btn btn-text"
          :class="[{ active: smScreenControlsShow }]"
          @click="toggleControls()"
        >
          Filter Drug Groups <font-awesome-icon icon="angle-down" />
        </button>
      </div>
    </portal>
      <transition-group name="fade">
       <div v-if="this.loadingData" key="1" class="text-center rfs-m-8">
        <loader />
      </div>
    </transition-group>

    <transition-group name="fade">

      <div v-if="!this.loadingData && drugGroupDetails!==undefined && drugGroupDetails.length === 0" key="2">
        <p class="card bg-warning-contrast color-white fw-600">
          <font-awesome-icon class="" icon="exclamation-circle" />
          There are no Drug Groups that match your filtered criteria. Try making
          your search more broad.
        </p>
      </div>
      <div v-else class="grid grid-cols-2 xl:grid-cols-2 gap-4" key="3">
        <drug-group-card
          v-for="dg in drugGroupDetails"
          :key="dg.description.id"
          data-ndc-card
          :drugGroup="dg"
          :drugGroupDesc="dg.description.name"
          :drugGroupHasPurchases="areTherePurchases(dg)"
          :ndcsPurchased="ndcsPurchased(dg)"
          :ndcTotalSpend="ndcTotalSpend(dg)"
          :percentPurchasesByAcountTypeData="
            percentPurchasesByAcountTypeData(dg)
          "
        />
      </div>
    </transition-group>
    <transition-group name="fade">
      <div
        v-if="
          drugGroupDetails !== undefined &&
          drugGroupDetails.length > 0 &&
          loadMoreVisible
        "
        key="4"
        class="text-center rfs-m-8"
      >
        <button
          data-load-more
          class="btn btn-sm btn-primary rfs-m-4-l"
          @click="loadMore"
        >
          <span>Load More</span>
        </button>
      </div>
    </transition-group>
    <!-- content in portal after-content will be transported to div following appContent -->
    <portal to="after-content">
      <div
        class="right-side-bar"
        :class="{
          'sm-screen-contols-show':
            smScreenControlsShow && !breakpoints.greaterThanMd,
          'sm-screen-contols': !breakpoints.greaterThanMd
        }"
      >
        <div
          class="rsb-content auto-scroll"
          :class="{ 'has-footer': !breakpoints.greaterThanMd }"
        >
          <form class="side-bar-controls" @submit.prevent="">
            <div :class="[{ 'form-disabled': loadingData }]">
              <date-range
                v-model="dateRange"
                class="rfs-m-8-b form-row"
                :disabled="loadingData"
                @input="onFilterChange()"
              />

              <multi-select-checkbox-dropdown
                v-if="needsHospitalSelector"
                v-model="hospitalSegment"
                :options="hospitalListOptions"
                optionLabel="text"
                fieldLabel="Hospital"
                :clearable="false"
                class="form-group form-row"
                :disabled="loadingData"
                @input="onFilterChange()"
              />
            </div>
          </form>
          <div v-if="showMultipleHospitalSelectNote" class="multi-hospital-select-note">Values displayed reflect the total of all hospitals selected.
          </div>
          <br>
          <form @submit.prevent="onFilterChange()">
            <h3 class="rfs-18 fw-sb rfs-m-4-b">Narrow Results by Searching</h3>
            <div
              class="single-input-search"
              :class="[{ 'form-disabled': loadingData }]"
            >
              <text-input
                :value="drugGroupLookup"
                fieldLabel="Drug Groups Matching Search Term"
                type="search"
                class="rfs-m-t-b rfs-m-4-b"
                fieldNote="You may search by Group Name, Drug Name, NDC, GPI or GPI description."
                :disabled="loadingData"
                @change="onFilterChange()"
                @input="
                  (v) => $store.commit('vr/mmg/drug_group_lookup/setValue', v)
                "
              />

              <button
                class="btn btn-text search-form-submit"
                type="submit"
                title="search now"
              >
                <font-awesome-icon class="date-picker-icon" icon="search" />
              </button>
            </div>
          </form>
        </div>
        <div
          class="mobile-filter-footer flex items-center"
          v-if="!breakpoints.greaterThanMd"
        >
          <button
            type="button"
            class="btn btn-text float-right flex-last-to-right"
            @click="toggleControls()"
          >
            View Results
          </button>
        </div>
      </div>
    </portal>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex';
  import map from 'lodash/map';
  import { calculatePercent, percentOfSum } from '@/js/utilities';

  // componets
  import selectStore from '@/store/modules/global/filters/select';
  import singleSelect from '@/components/base/forms/single_select';
  import dateRange from '@/components/base/forms/date_range';
  import TextInput from '@/components/base/forms/text_input.vue';
  import multiSelectCheckboxDropdown from '@/components/base/forms/multi_select_checkbox_dropdown';

  // data
  import { defaultDateRange, hospitalList } from '@/data';
  import drugGroupCard from '../../../components/popbuilder/drugGroupCard/drugGroupCard.vue';

  export default {
    name: 'DrugPricingByNdc',
    components: {
      singleSelect,
      dateRange,
      TextInput,
      drugGroupCard,
      multiSelectCheckboxDropdown
    },
    data() {
      return {
        noContent: true,
        actionableOpportunity: '-',
        hospitalListOptions: [],
        defaultHospitalName: false,
        defaultHospitalId: false,
        smScreenControlsShow: false,
        requestingTheseNdcs: '',
        forceDetailsRequestUpdate: 0,
        loadMoreVisible: false,
        offset: 0,
        pageSize: 10,
        totalRecords: 0,
        showMultipleHospitalSelectNote:false
      };
    },
    computed: {
      ...mapState('medicationCardGroupsManagement', {
        drugGroupIds: (state) => state.drugGroupIds,
        loadingDrugGroupIds: (state) => state.loadingDrugGroupIds,
        drugGroupDetails: (state) => state.drugGroupDetails,
        loadingDrugGroupDetails: (state) => state.loadingDrugGroupDetails
      }),
      ...mapState('vr/mmg/hospital_segment', {
        hospitalIds: (state) => ((state.value !== null && state.value.length > 0)
          ? state.value.map(hsp => hsp.value).reduce((a, r) => a+","+r) : '')
      }),
      ...mapState('vr/mmg/date_range', {
        startDate: (state) => state.value.start,
        endDate: (state) => state.value.end
      }),
      ...mapState('vr/mmg/drug_group_lookup', {
        drugGroupLookup: (state) => state.value.value
      }),
      ...mapState('auth', {
        selectedHospitalList: (state) => state.selectedHospitalList
      }),
      loadingData() {
        return this.loadingDrugGroupIds || this.loadingDrugGroupDetails;
      },
      needsHospitalSelector() {
        return this.hospitalListOptions.length >= 2;
      },
      hospitalSegmentDefault() {
        return {
          text: this.defaultHospitalName,
          value: this.defaultHospitalId
        };
      },
      dateRange: {
        get() {
          return this.$store.getters['vr/mmg/date_range/getValue'];
        },
        set(value) {
          return this.$store.commit('vr/mmg/date_range/setValue', value);
        }
      },
      hospitalSegment: {
        get() {
          return this.$store.getters['vr/mmg/hospital_segment/getValue'];
        },
        set(value) {
          this.$store.commit('auth/setSelectedHospitalList', value);
          return this.$store.commit('vr/mmg/hospital_segment/setValue', value);
        }
      },
      drugGroupLookup: {
        get() {
          return this.$store.getters['vr/mmg/drug_group_lookup/getValue'];
        },
        set(value) {
          return this.$store.commit('vr/mmg/drug_group_lookup/setValue', value);
        }
      },
      requestData() {
        return {
          endDate: this.endDate,
          hospitalIds: this.hospitalIds, // array to comma sep string
          startDate: this.startDate,
          accountType: 'GPO', // array to comma sep string - defaults to just gpo
        searchString: this.drugGroupLookup === null ? '' : this.drugGroupLookup,
        offset: this.offset,
        limit: this.pageSize
        };
      },
      currentOffset() {
        return this.drugGroupDetails.length;
      },
      requestDataWithPaging() {
        return {
          ...this.requestData,
          offset: this.currentOffset
        };
      },
      filtersReady() {
        if (this.endDate && this.hospitalIds && this.startDate) {
          return true;
        }
        return false;
      }
    },
    methods: {
      ...mapActions('medicationCardGroupsManagement', [
        'requestDrugGroupIds',
        'requestDrugGroupDetails'
      ]),
      ...mapMutations('medicationCardGroupsManagement', [
        'setDrugGroupIds',
        'setLoadingDrugGroupIds',
        'setDrugGroupDetails',
        'addResultsTodrugGroupDetails',
        'setloadingDrugGroupDetails'
      ]),
      drugGroupDetailsRequestData() {
        let toReturn = {
          endDate: this.endDate,
          hospitalIds: this.hospitalIds, // array to comma sep string
          startDate: this.startDate,
          ids: map(
            this.$store.getters[
              'medicationCardGroupsManagement/getDrugGroupIds'
            ],
            'id'
          ).join(',')
        };

        return toReturn;
      },
      percentPurchasesByAcountTypeData(drugGroup) {
        if (!this.areTherePurchases(drugGroup)) return null; // Stop errors

        const totalQtyClient = this.totalSegmentDataPoints(
          drugGroup.clientData,
          'qty'
        );

        const gpoClientPercent = calculatePercent(
          drugGroup.clientData.gpo ? drugGroup.clientData.gpo.qty : 0,
          totalQtyClient
        );

        const tfbClientPercent = calculatePercent(
          drugGroup.clientData['340b'] ? drugGroup.clientData['340b'].qty : 0,
          totalQtyClient
        );

        const wacClientPercent = calculatePercent(
          drugGroup.clientData.wac ? drugGroup.clientData.wac.qty : 0,
          totalQtyClient
        );

        const clientPercentTo100 = percentOfSum([
          gpoClientPercent,
          tfbClientPercent,
          wacClientPercent
        ]);

        // GPO account is called Index in the UI
        const gpo = [clientPercentTo100[0]];
        const threeFourtyB = [clientPercentTo100[1]];
        const wac = [clientPercentTo100[2]];

        return {
          datasets: [
            {
              label: 'Index',
              data: gpo,
              backgroundColor: [this.crcaColors.dataVis1]
            },
            {
              label: 'WAC',
              data: wac,
              backgroundColor: [this.crcaColors.dataVis2]
            },
            {
              label: '340B',
              data: threeFourtyB,
              backgroundColor: [this.crcaColors.dataVis3]
            }
          ]
        };
      },
      totalSegmentDataPoints(segmentData, prop) {
        let total = 0;
        for (const accountType in segmentData) {
          total = total + segmentData[accountType][prop];
        }
        return total;
      },
      areTherePurchases(drugGroup) {
        return (
          drugGroup.clientData && Object.keys(drugGroup.clientData).length != 0
        );
      },
      ndcsPurchased(drugGroup) {
        return drugGroup.description.ndcsPurchasedCount;
      },
      ndcTotalSpend(drugGroup) {
        return this.totalSegmentDataPoints(drugGroup.clientData, 'totalSpend');
      },
      toggleControls() {
        this.smScreenControlsShow = !this.smScreenControlsShow;
      },
      formatUiDetails(resp) {
        let drugDetailsById = this.$store.getters[
          'medicationCardGroupsManagement/getDrugGroupIds'
        ].map((drugGroupOrder) => {
          const item = resp.find(function (drugGroupDetail) {
            return drugGroupDetail.description.id == drugGroupOrder.id;
          });

          if (!item) {
            // these two lists should always have the same drug groups. If they don't something is wrong on the data side. So tossing a warning to let the FEDs know to talk to the API devs.
            console.warn(
              'drug grouping details list results does not contain ' +
                drugGroupOrder.id +
                ", however it was included in the response from the drugGroupingIds end point. Check with your friendly neighborhood API dev to figure out we don't have matching drug groups returned. The UI is dropping the Drug Group from the details order so this function doesn't error out and fail to produce any cards."
            );
            return;
          }
          return item;
        });

        // If we had a mismatch we need to remove the undefined items from our array before we map again to keep from killing the cards we do have and fail as gracefully as posible for the user.
        drugDetailsById = drugDetailsById.filter(function (item) {
          return item !== undefined;
        });

        return drugDetailsById;
      },
      onFilterChange() {

        this.showMultipleHospitalSelectNote = (this.hospitalSegment && this.hospitalSegment.length>1)? true: false;

      if (this.requestData.hospitalIds !== undefined && this.requestData.hospitalIds !== null && this.requestData.hospitalIds !== '') {
        window.scrollTo(0,0);
        this.loadMoreVisible=false;
        this.setLoadingDrugGroupIds(true);
        this.offset = 0;
        this.requestData.offset = 0;

        this.setDrugGroupDetails([]);
        this.requestDrugGroupIds({ requestData: this.requestData })
          .then((resp) => {
          let recordsCount = resp.headers['totalrecords'];
          this.totalRecords = parseInt(recordsCount);
          if (
            this.drugGroupDetails.length === this.totalRecords ||
            this.totalRecords <= this.pageSize
          ) {
            this.loadMoreVisible = false;
          } else {
            this.loadMoreVisible = true;
          }
          this.setDrugGroupIds(resp.data);
          })
          .finally(() => {
            if (
              this.$store.getters[
                'medicationCardGroupsManagement/getDrugGroupIds'
              ].length > 0
            ) {
              this.requestDrugGroupDetails({
                requestData: this.drugGroupDetailsRequestData()
              }).then((resp) => {
                const uiDetails = this.formatUiDetails(resp);
                this.setDrugGroupDetails(uiDetails);
              });
            }
            this.setLoadingDrugGroupIds(false);

          });
      }
    },
    loadMore() {
      this.loadNextPage();
    },
    loadNextPage() {
      if (this.requestData.hospitalIds !== undefined && this.requestData.hospitalIds !== null && this.requestData.hospitalIds !== '') {
        this.offset = this.offset + this.pageSize;
        // only try to load new cards if:
        // we have the required filters
        // we are not loading things
        // we we have loaded results but our last offset returned empty
        if (
          !this.loadingData &&
          this.$store.getters['medicationCardGroupsManagement/getDrugGroupIds']
            .length > 0
        ) {
          this.requestDrugGroupIds({ requestData: this.requestData })
            .then((resp) => {
            let allGroupIds =
              this.$store.getters[
                'medicationCardGroupsManagement/getDrugGroupIds'
              ];
            for (let id of resp.data) {
              allGroupIds.push(id);
            }
            let recordsCount = resp.headers['totalrecords'];
            this.totalRecords = parseInt(recordsCount);
            this.setDrugGroupIds(allGroupIds);
            })
            .finally(() => {
              if (
                this.$store.getters[
                  'medicationCardGroupsManagement/getDrugGroupIds'
                ].length > 0
              ) {
                this.requestDrugGroupDetails({
                  requestData: this.drugGroupDetailsRequestData()
                }).then((resp) => {
                  const uiDetails = this.formatUiDetails(resp);
                  this.setDrugGroupDetails(uiDetails);
                if (
                  this.drugGroupDetails.length ===this.totalRecords ||
                  this.totalRecords<= this.pageSize
                ) {
                  this.loadMoreVisible = false;
                } else {
                  this.loadMoreVisible = true;
                }
                });
              }
            });
          }
        }
      }
    },
    watch: {
      filtersReady() {
        this.onFilterChange();
      },
      selectedHospitalList(v) {
        if (v !== undefined && v !== null && v.length > 0) {
          this.hospitalSegment = v;
          this.onFilterChange();
        }
      }
    },
    beforeCreate() {
      // need to move all this to the same store, magic modules arent' the right tool here, but for now working around it to do other things
      window.vm.magicModule('vr/mmg/drug_group_lookup', selectStore);
      window.vm.magicModule('vr/mmg/date_range', selectStore);
      window.vm.magicModule('vr/mmg/hospital_segment', selectStore);
      if (!this.$store.getters['vr/mmg/hospital_segment/getValue']) {
        /* selected hospital List */
        hospitalList().then((hospitals) => {
          let selectedHospitalList = this.$store.getters['auth/getSelectedHospitalList'];
          if (selectedHospitalList !== undefined && selectedHospitalList !== null && selectedHospitalList.length > 0) {
            window.vm.$store.commit('vr/mmg/hospital_segment/setValue', selectedHospitalList);
          } else {
            window.vm.$store.commit('vr/mmg/hospital_segment/setValue', [{
              text: hospitals[0].text,
              value: hospitals[0].value,
              selected: true
            }]);
          }
        });
      }
      if (!this.$store.getters['vr/mmg/date_range/getValue']) {
        window.vm.$store.commit('vr/mmg/date_range/setValue', defaultDateRange);
      }
    },
    created() {
      this.setLoadingDrugGroupIds(true);
      hospitalList().then((list) => {
        const options = [];
        for (let i in list) {
          options.push({ text: list[i].text, value: list[i].value, selected: false });
        }
        this.hospitalListOptions = options;
      });
    },
    mounted() {
      this.onFilterChange();
    },
    beforeDestroy() {
      if (this.$store.hasModule('vr/mmg/hospital_segment')) {
        this.$store.unregisterModule('vr/mmg/hospital_segment');
      }
    }
  };
</script>

<style lang="scss">
  @use '@/assets/scss/responsive_grid.scss' as rg;
  @use '@/assets/scss/margin_padding.scss' as mp;
  .medication-group-manage-cards {
    #appContent {
      min-height: calc(100vh - 220px);
    }
    .card-percent-bar {
      span {
        display: inline-block;
        min-width: 20px;
      }
      .percent-bar {
        @extend .rfs-p-2;
      }
    }

    .percent-by-account-type-chart {
      height: 50px;
    }

    .card:not(.small) {
      .card-subtitle {
        @extend .grid;
        @extend .grid-cols-2;
      }
      .account-toggles {
        @extend .grid;
        @extend .grid-cols-3;
      }
    }

    .card.small {
      .card-subtitle {
        div {
          @extend .rfs-m-2-b;
        }
      }
      .account-toggles {
        label {
          @extend .rfs-m-2-b;
        }
      }
    }

    .grid .callout {
      grid-row: 1;
    }

    .multi-hospital-select-note{
      font-size: 0.875rem;
    }

  }
</style>
